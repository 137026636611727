/** @jsx jsx */
import Section from "blocks/Section"
import Post from "components/Post"
import { jsx, css, Global } from "@emotion/react"

interface Props {
  posts: Post[]
}

const variables = css`
  :root {
    --posts-columns: 1;
  }

    /* 520px */
    @media screen and (min-width: 32.5rem) {
    :root {
      --posts-columns: repeat(2, 1fr);
    }
  }

  /* 720px */
  @media screen and (min-width: 64rem) {
    :root {
      --posts-columns: repeat(3, 1fr);
    }
  }
`

const styles = css`
  &[data-posts] [data-card] {
    margin-bottom: 1.5rem;
  }


  &[data-posts] {
    display: grid;
    grid-template-columns: var(--posts-columns);
    grid-gap: 3rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  /* 1280px */
  @media screen and (min-width: 80rem) {
    &[data-posts] {
      margin-left: auto;
      margin-right: auto;
    }

    &[data-posts] [data-card] {
      max-width: 24.034375rem;
    }
  }


  [data-post-link] {
    width: 100%;
    margin-top: 1.5rem;
    text-align: right;
    display: inline-block;
  }
`

const Posts: FC<Props> = ({ posts }) => (
  <Section css={styles} data-posts>
    <Global styles={variables} />
    {posts.map((post) => (
      <Post key={`${post.id}-${post.slug}`} post={post} />
    ))}
  </Section>
)


export default Posts
