/** @jsx jsx */
import { jsx, css, Global } from "@emotion/react"
import { Link } from "gatsby"
import Card from "components/Card"

interface Props {
  post: Post
}

const variables = css`
  :root {
    --nav-menu-display: none;
  }

  @media screen and (min-width: 64rem) {
    :root {
      --nav-menu-display: inline;
    }
  }
`

const styles = css`
  [data-post] {
    display: flex;
    position: relative;
  }

  [data-post] img {
    max-height: 12.5rem;
  }

  [data-post-nav-menu] {
    order: 1;
    top: 20%;
    margin-top: 1rem;
    z-index: 2;
    left: 100%;
    display: var(--nav-menu-display);
    position: sticky;
    align-self: flex-start;
  }

  [data-post] h1,
  [data-post] h2,
  [data-post] h3,
  [data-post] h4,
  [data-post] h5,
  [data-post] h6 {
    scroll-margin-top: 6rem;
  }

  [data-post-card-body] {
    height: 3rem;
    text-overflow: ellipsis;
  }

  [data-post-card-link] {
    width: 100%;
    display: block;
    text-align: right;
  }
`

const Post: FC<Props> = ({ post }) => (
  <Card as="article" css={styles} data-post>
    <Global styles={variables}/>
    <Card.Header>
      <img src={post.previewImgSrc} alt={post.previewImgAlt} />
    </Card.Header>
    <Card.Body>
      <h1>{post.title}</h1>
      <p data-post-card-body>
        {post.brief}
      </p>
      <Link data-post-card-link to={`/${post.slug}`}>Read more</Link>
    </Card.Body>
  </Card>
)


export default Post
