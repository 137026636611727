import Base from "layouts/Base"
import Posts from "blocks/Home/Posts"
import { graphql } from "gatsby"



interface QueryData {
  allSitePage: {
    edges: {
      node: {
        context: {
          data: {
            frontmatter: FrontMatter
          }
        }
      }
    }[]
  }
}

const Home: PFC<QueryData> = ({ data }) => {
  const posts = data?.allSitePage.edges.filter(edge =>
    edge.node.context.data?.frontmatter
  ).map(edge =>
    edge.node.context.data?.frontmatter
  ) || []
  return (
    <Base>
      <Posts posts={posts as Post[]}/>
    </Base>
  )
}


export const query = graphql`
  query HomePageQuery {
    allSitePage {
      edges {
        node {
          context {
          data {
            frontmatter {
              slug
              title
              brief
              heroImgSrc
              heroImgAlt
              previewImgSrc
              previewImgAlt
              headings
            }
          }
          }
        }
      }
    }
  }
`

export default Home
