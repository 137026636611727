import styled from "@emotion/styled"
import { ElementType } from "react"


const StyledCard = styled.div`
  &[data-card] {
    border: 1px solid;
    border-radius: 2px;
    padding-bottom: 1.5rem;
    transition: background-color 0.2s;
  }

  [data-card-image-container] {
    margin: 0;
    background-color: var(--black);
  }

  [data-card-image-container] img {
    width: 100%;
    max-height: 18rem;
    object-fit: contain;
  }
`


interface Props {
  as?: ElementType
}

type CompoundCard = FC<Props> & {
  Header: FC,
  Body: FC
}

const Card: CompoundCard = ({ as = 'div', children, ...rest }) => (
  <StyledCard as={as} data-card {...rest}>
    {children}
  </StyledCard>
)

Card.Header = ({ children, ...rest }) => (
  <figure data-card-image-container {...rest}>
    {children}
  </figure>
)

Card.Body = ({ children, ...rest }) => (
  <footer data-section="inline" {...rest}>
    {children}
  </footer>
)


export default Card
